import React from 'react';
import Modal from '../components/Modal';
import { SlCalender } from 'react-icons/sl';
import image1 from '../components/Assets/VIT.png';
import image2 from '../components/Assets/Ryan.png';
import Navbar from '../components/Navbar';

function Education() {
  return (
    <div>
      <div className="mt-3 mx-3" >
        <Navbar />
      </div>
      <div className="d-flex flex-row justify-content-center align-items-center p-4" >
        <div className="row">
          <div className="col mb-4">
            <h1 className="font-weight-bolder text-left mb-4 w-100" style={{ fontFamily: "Nunito Sans, sans-serif" }}>Education</h1>
            <div>
              <div>
                <Modal
                  name="Vellore Institute of Technology"
                  course="B.Tech Computer Science"
                  time={<><SlCalender /> 2021 - Present</>}
                  Grade="% Grade : 8.46 CGPA"
                  image={image1}
                />
              </div>
              <div>
                <Modal
                  name="Ryan International School (12th)"
                  course="12th Board PCM"
                  time={<><SlCalender /> 2020 - 2021</>}
                  Grade="% Grade : 91.4 %"
                  image={image2}
                />
              </div>
              <div>
                <Modal
                  name="Ryan International School (10th)"
                  course="10th Board All Compulsory Subjects"
                  time={<><SlCalender /> 2018 - 2019</>}
                  Grade="% Grade : 91.6 %"
                  image={image2}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Education;
