import React from 'react'
import { BsFileEarmarkText } from "react-icons/bs";
function Resume(props) {
    const {title, direction} = props;
    return (
        <>
            <button type="button" class="buttonStyle" data-bs-toggle="modal" data-bs-target="#staticBackdrop2">
                <BsFileEarmarkText style={{ color: '#3d424a', marginRight: '5px' }} />
                Resume
            </button>
            <div class="modal fade" id="staticBackdrop2" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h1 class="modal-title fs-5" id="staticBackdropLabel">Resume</h1>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <iframe title={title} src={direction} width="100%" height="500px"></iframe>
                        </div>
                        <div class="modal-footer">
                            <a rel="noreferrer" target="_blank" href="https://drive.google.com/file/d/13gFFO9cjOcXat3tLRTVtG0m34C366jjP/preview"><button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Open</button></a>
                            <a href="https://drive.google.com/file/d/13gFFO9cjOcXat3tLRTVtG0m34C366jjP/preview" download="Resume.pdf">
                                <button type="button" class="btn btn-primary">Download</button>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Resume