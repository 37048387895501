import React from 'react'
import './Css/Buttons.css';

import Contact from './Contact';
import Resume from './Resume';
const Buttons = () => {
  return (
    <div className='d-flex flex-row justify-content-between'>
      <Contact />
      <Resume  direction = "https://drive.google.com/file/d/13gFFO9cjOcXat3tLRTVtG0m34C366jjP/preview" title = "My Resume"/>
    </div>
  )
}

export default Buttons;