import React from 'react';

function Modal(props) {
    const { name, course, time, Grade, image } = props;

    return (
        <div className="container model-pack">
            <div className="row align-items-start">
                <div className="col-sm-12 col-lg-2 d-flex justify-content-center align-items-center align-self-center">
                    <img src={image} alt="" className="img-fluid modal-image mx-auto d-block" />
                </div>
                <div className="col-sm-12 col-lg-10">
                    <div className="text">
                        <div className='modal-name'>{name}</div>
                        <div className='modal-course'>{course}</div>
                        <div className='modal-time'>{time}</div>
                        <div className='modal-grade'>{Grade}</div>
                        <div className='modal-button'></div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Modal;
