import React from 'react';
import Navbar from '../components/Navbar';

function About() {
  return (
    <>
      <div className="mt-3 mx-3" >
        <Navbar />
      </div>
      <div className="container d-flex flex-wrap align-items-start">
        <div className="row justify-content-center align-items-center">
          <div className="col-10 col-md-8 col-lg-6">
            <div className="about-div text-left">
              <h1 className="font-weight-bolder text-left mb-4 w-100" style={{ fontFamily: "Nunito Sans, sans-serif" }}>About</h1>
              {/* Moved the heading above the paragraph */}
              <p>
                As an enthusiastic forth-year Computer Science and Engineering student at Vellore Institute of Technology, I'm diving deeper into the realms of technology and innovation. With a blend of dedication and skill, I've ventured into crafting innovative solutions for real-world challenges. My expertise covers various aspects of web development, particularly in the fascinating world of the MERN stack. I'm also actively learning and mastering Python due to its versatility and power across different domains.
              </p>
              <p>
                I have a keen interest in exploring cutting-edge technologies, especially in the realm of machine learning, where the fusion of data science and AI captivates me.
              </p>
              <p>
                Moreover, I've had the privilege of gaining practical experience through internships. One was as a web development intern, where I honed my skills in front-end and back-end development. The other was as an educational assistant for C++, delving deep into programming languages and concepts.
              </p>
              <p>
                I thrive in collaborative environments, engaging with the community to drive growth and innovation.
              </p>
              <p>
                If you have any queries, projects, or interesting ideas within my area of expertise, feel free to reach out! I'm here to share my knowledge, learn from others, and together, we can make a meaningful impact in the realm of technology.
              </p>
              <p>
                Namaste!
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default About;
